import { ref } from 'vue';
import { projectFirestore,projectStorage,projectAuth } from '../../../Firebase/config';
import {get_date_now} from '../../../Scripts/dates'
import {user} from '../../../Scripts/Utils'



const use_chat_messages_storage = () => {
    const error = ref(null);
  
    // url for download the image
    const url = ref(null);
  
    // Path for firestore
    const filePath = ref(null);
  
    const uploadImage = async (file) => {
      filePath.value = `Support_chat_messages/${projectAuth.currentUser.uid}/${get_date_now()}`;
      const storageRef = projectStorage.ref(filePath.value);
  
      try {
        // upload the file
        const res = await storageRef.put(file);
        url.value = await res.ref.getDownloadURL();
        await store_in_db()
      } catch (err) {
        console.log(err.message);
        error.value = err.massage;
      }
    };

    const store_in_db=async()=>{
        const doc = projectFirestore.collection('Support').doc('Messages')
        .collection(projectAuth.currentUser.uid).doc()
        await doc.set({
            createdAt: get_date_now(),
            msgId:doc.id,
            name:user.value.name,
            image:url.value,
            type:'client',
            read:false
        })
    }

    


    return { error, url, filePath, uploadImage };
};

export default use_chat_messages_storage;