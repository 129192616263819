<template>
    <TriggerChat v-if="!is_show_support_chat && current_user"/>
    <div v-if="is_show_support_chat && current_user" v-loading="upload_pending" ref="chat_box" class="chat-box slide-in-right">
        <div class="chat-header">
            <div class="close-btn" @click="handle_close_chat_window">
                <i class="material-symbols-rounded" style="text-shadow:none;">highlight_off</i>
            </div>
            <div class="minimize-btn" ref="minimize_btn" @click="handle_minimize_chat_window">
                 <i class="material-symbols-rounded" style="text-shadow:none;">expand_more</i>
            </div>
            <div v-if="state=='in_session'" class="capture-btn" @click="handle_capture_image">
                <el-tooltip
                    class="item"
                    effect="dark"
                    content="כפתור זה מיועד לצילום המסך הנוכחי"
                    placement="top"
                >
                 <span class="material-symbols-rounded camera-icon" style="text-shadow:none;" >camera_alt</span>
                </el-tooltip>
            </div>
            <p>תמיכה</p>
        </div>
        <div v-if="state=='in_session'" class="chat-msgs">
            <template v-for="(msg,index) in sort_messages" :key="msg.msgId">
                <div v-if="index==0" class="messages-date">
                <p>{{display_date_from_iso(msg.createdAt)}}</p>
                </div>
                <div v-if="index>0 && display_date_from_iso(sort_messages[index-1].createdAt) != display_date_from_iso(msg.createdAt)" class="messages-date">
                    <p>{{display_date_from_iso(msg.createdAt)}}</p>
                </div>
                <div v-if="msg.type=='supporter'" class="chat-line-service">
                    <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                    <!-- <p style="font-weight:bold; margin-left:10px;">{{msg.name}}</p> -->
                    <p>{{msg.text_msg}}</p>
                </div>
                <div dir="rtl" v-if="msg.type=='client'" class="chat-line-client">
                    <p style="margin-left:10px; font-size:0.7rem;">{{display_only_time_from_iso(msg.createdAt)}}</p>
                    <p v-if="msg.text_msg">{{msg.text_msg}}</p>
                    <img class="snap-shot-img" v-if="msg.image" :src="msg.image">
                    <a class="open-image" v-if="msg.image" :href="msg.image" target="_blank"></a>
                    <span style="margin: 0 5px;" v-if="msg.read" class="material-symbols-rounded icon-done">done_all</span>
                    <span style="margin: 0 5px;" v-if="!msg.read" class="material-symbols-rounded icon-check">check</span>
                </div>
            </template>  
        </div>
        <div v-if="state=='in_session'" class="chat-input">
            <el-input v-model="msg_text" @keypress.enter="handle_msg_text" style="width:75%;" :input-style="{'width': '100%', 'height': '100%', 'font-size': '20px'}" />
            <el-button  @click="handle_msg_text" style="width:23%; height:100%; margin:0; flex-shrink: 0;" type="success">שלח</el-button>
        </div>
        <div v-if="state=='before_start'" class="before-start">
            <h1>שלום</h1>
            <h2>{{current_user.name}}</h2>
            <p>ע"מ להתחיל בקבלת מתן תמיכה</p>
            <p>כל שעליך לעשות הוא ללחוץ על כפתור התחל צא'ט</p>
            <el-button @click="handle_start_chat" style="margin-top:15px" type="success">התחל צ'אט</el-button>
        </div>
        <div v-if="state=='pending'" class="pending">
            <h1>מחפש נציג שירות</h1>
            <p>אנא המתן בבקשה</p>
            <small>מיקומך בתור:{{pending_requests.length}}</small>
            <el-button @click="handle_cancel"  style="margin-top:15px" type="danger">בטל</el-button>
        </div>
        <div v-if="state=='ended'" class="ended">
            <h1>התומך סיים את השיחה</h1>
            <p>{{new Date().toLocaleDateString()}}, {{new Date().toLocaleTimeString('he')}}</p>
            <el-button @click="handle_cancel"  style="margin-top:15px" type="danger">סגור</el-button>
        </div>
    </div>
    
  <!-- just for mail -->
    <div v-if="current_user" class="form-email" id="form-email" v-show="false">
      <table
        style="width:80%; border-collapse: collapse; font-size: 1.1rem; margin: 1rem 0;"
      >
        <tr style="background-color: #dddddd;">
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            תאריך וזמן
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{ new Date().toLocaleDateString('he') }},{{new Date().toLocaleTimeString('he')}}
          </td>
        </tr>
        <tr>
          <th style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            שם המשתמש
          </th>
          <td style="border:1px solid #dddddd; text-align:right; padding: 8px;">
            {{current_user.name}}
          </td>
        </tr>
        
      </table>
    </div>
</template>

<script>
import { computed, onMounted, onUnmounted, onUpdated, ref, watch } from '@vue/runtime-core'
import store from '../../store'
import {projectFirestore,projectFunctions} from '../../Firebase/config'
import {alert_confirm} from '../../Scripts/Msgs'
import TriggerChat from './TriggerChat.vue'
import {get_date_now,display_only_time_from_iso,get_object_date_with_time,display_date_from_iso} from '../../Scripts/dates'
import {handle_read_msg,capture_snapshot,upload_pending} from './Scripts/scripts'

export default {
    emits:['capture'],
    components:{TriggerChat},
    setup(_,context){
        const chat_box = ref()
        const minimize_btn = ref()

        const is_show_support_chat = ref(computed(()=>{
            return store.state.client.show_support_chat
        }))
        const state = ref('before_start')
        const pending_requests_unsub = ref(null)
        const massages_unsub = ref(null)

        const current_user = ref(computed(()=>{
            return store.state.user
        }))
        
        const pending_requests = ref([])
        
        const messages = ref([])

        const msg_text = ref('')

        const sort_messages = ref(computed(()=>{
            if(messages.value.length){
                return messages.value.sort((a,b)=>{
                    return get_object_date_with_time(a.createdAt) - get_object_date_with_time(b.createdAt)
                })
            }
        }))

        const handle_msg_text = async()=>{
            const doc = projectFirestore.collection('Support').doc('Messages')
            .collection(current_user.value.uid).doc()
            await doc.set({
                createdAt:get_date_now(),
                msgId:doc.id,
                name:current_user.value.name,
                type:'client',
                text_msg:msg_text.value,
                read:false
            })
            msg_text.value=''
        }

        const handle_start_chat = async()=>{
            localStorage.setItem('user_chat',JSON.stringify({
                status:'pending',
            }));
            await ask_request()
            state.value = 'pending'
            //send email to supporter

            //sendEmail()
            pending_request_RT()
        }

        function sendEmail() {
          projectFunctions.httpsCallable('send_email')({
              html: document.getElementById("form-email").innerHTML,
              destinations:["mosescorcias@gmail.com","yavrosman@gmail.com"],
              from:"תמיכה",
              title:`נפתחה בקשת תמיכה חדשה, משתמש:${current_user.value.name}`
          })
        }

        const handle_cancel = async()=>{
            await remove_request()
            state.value = 'before_start'
            reset()
        }
        const reset = ()=>{
            pending_requests.value = []
            messages.value = []
            if(pending_requests_unsub.value){
                pending_requests_unsub.value()
            }
            if(massages_unsub.value){
                massages_unsub.value()
            }
            localStorage.removeItem('user_chat');
        }
        const remove_request = async()=>{
            await projectFirestore.collection('Support').doc('Pending')
            .collection('PendingRequests').doc(current_user.value.uid).delete()
        }
        const ask_request = async()=>{
            await projectFirestore.collection('Support').doc('Pending')
            .collection('PendingRequests').doc(current_user.value.uid).set({
                createdAt: get_date_now(),
                user_uid:current_user.value.uid,
                status:'pending',
                display_name:current_user.value.name,
                user_image: current_user.value.photo_url,
                user_phone: current_user.value.phone,
            })
        }
        const pending_request_RT = ()=>{
           pending_requests_unsub.value =  projectFirestore.collection('Support').doc('Pending').collection('PendingRequests').onSnapshot(snapshot => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        if(change.doc.data().status=='pending'){
                            pending_requests.value.push(change.doc.data())
                        }
                    }
                    if (change.type === "modified") {
                        if(change.doc.data().user_uid==current_user.value.uid && change.doc.data().status=='in_session'){
                           state.value = 'in_session' 
                        }
                        if(change.doc.data().user_uid==current_user.value.uid && change.doc.data().status=='ended'){
                           state.value = 'ended' 
                        }
                        const index = pending_requests.value.findIndex(request=>request==change.doc.data().uid)
                        if(index!=-1){
                            pending_requests.value[index] = change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        const index = pending_requests.value.findIndex(request=>request==change.doc.data().uid)
                        if(index!=-1){
                            pending_requests.value.splice(index,1)
                        }
                    }
                })
            })
        }

        const handle_close_chat_window = () => {
            if(state.value!='before_start'){
                alert_confirm('האם לסגור חלון הצאט?')
                .then(async(res)=>{
                    if(res.isConfirmed){
                        await remove_request()
                        reset()
                        toggle_comp()
                    }
                })
            }else{
                toggle_comp()
            }
        }

        const handle_minimize_chat_window = ()=>{
           chat_box.value.classList.toggle('minimize-chat');
           minimize_btn.value.classList.toggle('rotate-icon')
        }
        
        const toggle_comp = () => {
            store.state.client.show_support_chat = !store.state.client.show_support_chat
            state.value = 'before_start'
        }

        const messages_RT = ()=>{
            massages_unsub.value = projectFirestore.collection('Support').doc('Messages').collection(current_user.value.uid).onSnapshot(snapshot => {
                 snapshot.docChanges().forEach((change) => {
                    if (change.type === "added") {
                        const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId)
                        if(index==-1){
                            messages.value.push(change.doc.data())
                            if(change.doc.data().type=='supporter' && !change.doc.data().read){
                                handle_read_msg(current_user.value.uid,change.doc.data().msgId)
                            }
                            if(chat_box.value && chat_box.value.classList.contains('minimize-chat')){
                                handle_minimize_chat_window()
                            }
                        }
                    }
                    if (change.type === "modified") {
                        const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId) 
                        if(index!=-1){
                            messages.value[index]=change.doc.data()
                        }
                    }
                    if (change.type === "removed") {
                        const index = messages.value.findIndex(msg=>msg.msgId==change.doc.data().msgId) 
                        if(index!=-1){
                            messages.value.splice(index,1)
                        }
                    }
                 })
            })
        }

        const handle_capture_image = ()=>{
            capture_snapshot()
        }

        onUpdated(()=>{
            if(document.querySelector('.chat-msgs')){
                document.querySelector('.chat-msgs').scrollTop= document.querySelector('.chat-msgs').scrollHeight;

            }
        })

        watch(state,()=>{
            if(state.value=='in_session'){
                localStorage.setItem('user_chat',JSON.stringify({
                    status:'in_session',
                }));
                messages_RT()
            }
            if(state.value=='ended'){
                localStorage.setItem('user_chat',JSON.stringify({
                    status:'ended',
                }));
            }
        })

        onMounted(()=>{
            const local_data = JSON.parse(localStorage.getItem('user_chat'))
            if(local_data){
                if(local_data.status=='pending'){
                    state.value = 'pending',
                    pending_request_RT()
                }
                if(local_data.status=='in_session'){
                    state.value = 'in_session'
                  
                    pending_request_RT()
                    messages_RT()
                }
                if(local_data.status=='ended'){
                    state.value = 'ended'
                }
            }
        })

        onUnmounted(async()=>{
            if(pending_requests_unsub.value){
                await pending_requests_unsub.value()
            }
            if(massages_unsub.value){
                await massages_unsub.value()
            }
        })
        
        return{
            handle_minimize_chat_window,
            handle_start_chat,
            handle_cancel,
            toggle_comp,
            current_user,
            state,
            pending_requests,
            handle_close_chat_window,
            messages,
            msg_text,
            handle_msg_text,
            sort_messages,
            handle_capture_image,
            is_show_support_chat,
            chat_box,
            minimize_btn,
            display_only_time_from_iso,
            display_date_from_iso,
            upload_pending,
        }
    }
}
</script>

<style scoped>
    .chat-box{
        position: fixed;
        bottom: 10px;
        right: 10px;
        width: 340px;
        max-width: calc(100% - 10px);
        height: 550px;
        max-height: calc(100% - 20px);
        background: #fff;
        border-radius: 10px;
        overflow: hidden;
        z-index: 1000;
        transition: height 0.2s;
    }
    .chat-header{
        position: relative;
        width: 100%;
        height: 50px;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        background: var(--turkiz);
        color: white;
        font-size: 24px;
        text-shadow: 0 0 3px rgba(0, 0, 0, 0.76);
    }
    .camera-icon{
        color: var(--turkiz);
        user-select: none;
    }
    .icon-done,.icon-check{
        font-size: 14px;
        color: var(--success);
        align-self: flex-end;
        user-select: none;
    }
    .icon-check{
       color: var(--dark_bg); 
    }
    .chat-msgs{
        position: relative;
        width: 100%;
        height: calc(100% - 130px);
        overflow:hidden;
        overflow-y: auto;
        padding: 3px;
        display: flex;
        flex-direction: column;
    }
    .chat-line-service,.chat-line-client{
        position: relative;
        width: fit-content;
        padding: 5px;
        background-color: rgb(223, 221, 221);
        display: flex;
        margin-bottom: 10px;
        flex-wrap: wrap;
        overflow: hidden;
        align-items: center;
        border-radius: 10px;
        flex-shrink: 0;
    }
    .messages-date{
        width: 100%;
         color: rgb(192, 189, 189);
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1px;
        background-color: unset;
        font-size: 14px;
    }
    .chat-line-service{
        align-self: flex-end;
        direction: rtl;
    }
    .chat-line-client{
        background-color: #dcf8c6;
    }
    .chat-input{
        padding: 5px;
        position: relative;
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        flex-shrink: 0;
    }
    .chat-input{
        flex-direction: row-reverse;
    }
    .close-btn{
        position: absolute;
        top: 10px;
        left: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--danger);
        cursor: pointer;
    }
    .close-btn:hover i{
        transform: scale(0.95);
    }
    .close-btn i {
        transition: 0.25s;
        user-select: none;
    }
    .minimize-btn{
        position: absolute;
        top: 10px;
        left: 50px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color: var(--turkiz);
        cursor: pointer;
        transition: transform 1s;
        user-select: none;
    }
    .capture-btn{
        position: absolute;
        top: 10px;
        right: 5px;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        color:#333;
        cursor: pointer;
    }
    .minimize-btn.rotate-icon{
        transform: rotate(-180deg);
    }
    .chat-box.minimize-chat{
        height: 50px;
        width: 100px;
        background: unset;
    }
    .chat-box.minimize-chat .chat-header{
        justify-content: flex-end;
        font-size: 20px;
        padding: 0 5px;
    }
    .chat-box.minimize-chat .close-btn{
        display: none;
    }
    .chat-box.minimize-chat .minimize-btn{
        left: 5px;
    }
    .chat-box.minimize-chat .capture-btn{
        display: none;
    }

    .chat-box.minimize-chat > div:not(:first-child){
        display: none;
    }
   

    .slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    }

    .before-start,.pending,.ended{
        width: 100%;
        height: calc(100% - 50px);
        display: flex;
        flex-direction: column;
        align-items: center;
        line-height: 1.4
    }

    .snap-shot-img{
        max-height: 100%;
        max-width: 100%;
    }

    .open-image::after{
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

</style>