import { projectFirestore } from "../../../Firebase/config"
import  html2canvas from 'html2canvas'
import use_chat_messages_storage from '../Scripts/use_chat_messages_storage'
import { ref } from "vue"

const upload_pending = ref(false)
const {uploadImage}  = use_chat_messages_storage()

const handle_read_msg = async(user_uid,msg_id)=>{
    await projectFirestore.collection('Support').doc('Messages')
    .collection(user_uid).doc(msg_id)
    .set({
        read:true
    },{merge:true})
}

const capture_snapshot=async()=>{
    html2canvas(document.getElementById('main-router')).then(canvas => {
        canvas.toBlob(async(blob)=>{
            upload_pending.value = true
            await uploadImage(blob)
            upload_pending.value = false
        })
    });
}

export {
    handle_read_msg,
    capture_snapshot,
    upload_pending
}